body {
  font-family: local(Calmetta_W_Lt);
  overflow: hidden;
}

@font-face {
  font-family: 'Calmetta';
  src: local(Calmetta_W_Lt), url('fonts/Calmetta_W_Lt.woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Calmetta';
  src: local(Calmetta_W_Rg), url('fonts/Calmetta_W_Rg.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Calmetta';
  src: local(Calmetta_W_Bd), url('fonts/Calmetta_W_Bd.woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Calmetta';
  src: local(Calmetta_W_XBd), url('fonts/Calmetta_W_XBd.woff2');
  font-weight: 800;
}

.fp-table {
  justify-content: unset;
}

.fp-overflow {
  height: 100%;
  overflow: hidden;
}

.fp-watermark {
  display: none;
}

.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: purple;
  z-index: 2;
}

@media (max-width: 1280px) {
  svg {
    transform: scale(0.9) !important;
  }

  .lottieIPadSmaller svg {
    transform: scale(0.7) !important;
  }

  .idleMode svg,
  .fixedSize svg,
  .bubbles svg {
    transform: scale(1) !important;
  }

  .imgSmaller {
    width: 60%;
  }

  .ipadFull {
    width: 100%;
  }

  .fillingText {
    transform: scale(0.9);
    -webkit-transform-origin-x: left;
    -webkit-transform-origin-y: top;
  }

  .lottie-wrapper {
    width: 100%;
    height: 100%;
  }

  .lottie-default {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

/* Revealers */

.revealer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  pointer-events: none;
}

.revealer--cornertopleft,
.revealer--cornertopright,
.revealer--cornerbottomleft,
.revealer--cornerbottomright {
  top: 50%;
  left: 50%;
}

.revealer--top,
.revealer--bottom {
  left: 0;
}

.revealer--right,
.revealer--left {
  top: 50%;
  left: 50%;
}

.revealer--top {
  bottom: 100%;
}

.revealer--bottom {
  top: 100%;
}

.revealer__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ddd;
}

/* Two layer effect (effect-2) */

.anim--effect-2 .page:first-child {
  background: #9cdab1;
}

.anim--effect-2 .page:nth-child(2) {
  background: #f9c969;
}

.anim--effect-2 .revealer--animate .revealer__layer {
  -webkit-animation: anim-effect-2-1 1.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
  animation: anim-effect-2-1 1.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

.anim--effect-2 .revealer--animate .revealer__layer:nth-child(2) {
  -webkit-animation-name: anim-effect-2-2;
  animation-name: anim-effect-2-2;
}

@-webkit-keyframes anim-effect-2-1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  30%,
  70% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }
  100% {
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

@keyframes anim-effect-2-1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  30%,
  70% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }
  100% {
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

@-webkit-keyframes anim-effect-2-2 {
  0%,
  14.5% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  37.5%,
  62.5% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }
  85.5%,
  100% {
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

@keyframes anim-effect-2-2 {
  0%,
  14.5% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  37.5%,
  62.5% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }
  85.5%,
  100% {
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

/* Three layer effect (effect-3) */

.anim--effect-3 .page:nth-child(2) {
  background: #ecf3a3;
}

.anim--effect-3 .revealer--animate .revealer__layer {
  -webkit-animation: anim-effect-3-1 1.5s cubic-bezier(0.55, 0.055, 0.675, 0.19)
    forwards;
  animation: anim-effect-3-1 1.5s cubic-bezier(0.55, 0.055, 0.675, 0.19)
    forwards;
}

.anim--effect-3 .revealer--animate .revealer__layer:nth-child(2) {
  -webkit-animation-name: anim-effect-3-2;
  animation-name: anim-effect-3-2;
}

.anim--effect-3 .revealer--animate .revealer__layer:nth-child(3) {
  -webkit-animation-name: anim-effect-3-3;
  animation-name: anim-effect-3-3;
}

@-webkit-keyframes anim-effect-3-1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  25%,
  75% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

@keyframes anim-effect-3-1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  25%,
  75% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

@-webkit-keyframes anim-effect-3-2 {
  0%,
  12.5% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  37.5%,
  62.5% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  87.5%,
  100% {
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

@keyframes anim-effect-3-2 {
  0%,
  12.5% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  37.5%,
  62.5% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  87.5%,
  100% {
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

@-webkit-keyframes anim-effect-3-3 {
  0%,
  25% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  75%,
  100% {
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}

@keyframes anim-effect-3-3 {
  0%,
  25% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  75%,
  100% {
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}
